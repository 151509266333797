export default {
    titleName: process.env.PUBLIC_URL + '/assets/keenan.png',
    keenanBW: process.env.PUBLIC_URL + '/assets/selfie2.jpg',
    keenanColor: process.env.PUBLIC_URL + '/assets/selfie1.jpg',
    signature: process.env.PUBLIC_URL + '/assets/KCsignature.png',
    insta: process.env.PUBLIC_URL + '/assets/instagram.svg',
    mail: process.env.PUBLIC_URL + '/assets/mail.svg',
    resumePhoto: process.env.PUBLIC_URL + '/assets/resume.svg',
    back: process.env.PUBLIC_URL + '/assets/left-arrow.svg',
    about: process.env.PUBLIC_URL + '/assets/about.png',
    contact: process.env.PUBLIC_URL + '/assets/contact.png',
    bio: "Keenan Cassidy (b. 1994) is a visual artist from Worcester, MA. Early exhibitions of his were hosted in places ranging from a thrift store to a tattoo parlor. His sculptures are often multimedia - combining cast metals, ceramics, and textiles. His paintings are also tactile and often based on collages or on-site locations.",
    bio2: "He has a BFA in Sculpture from RISD (2016) with coursework in Painting, and Urban Studies at Brown.",
    bio3: "Works have investigated lost places, motorsports, history, the anthropocene, and social geography. He seeks to explore the purity of materials, sound, and human form. Immersion into new places, or experiencing a place ‘with fresh eyes’ is a perspective that guides his work.",
    bio4: "Keenan also has a teaching practice, and can be found instructing drawing and painting students at Fitchburg High School in MA.",
    resume: process.env.PUBLIC_URL + '/assets/resume2021.pdf'

 };
