import React from 'react'
import { useDataLayerValue } from '../DataLayer'
import content from '../content';


function Content({data}) {

    const [{page},dispatch] = useDataLayerValue();

    const mainPage = () => {
        dispatch({
          type: "SET_PAGE",
          page: "main",
        });
      }
    
    return (
        <div>
            <img className={"absolute mx-4 my-2 w-8 opacity-40 hover:opacity-100"} onClick={mainPage} src={content.back}></img>
            <div className={"place-content-center flex flex-wrap h-screen w-screen"} >
                <div className={" h-5/6 w-full flex place-content-center p-4"}>
                    <img className={"object-contain "} src={data.link} ></img>
                </div>
                <div className={" h-1/6 w-full content-center  "}>
                    <h1 className={" text-base m-2 text-center italic"} >{data.title}  <span className="text-gray-400">||</span>  {data.material}  <span className="text-gray-400">||</span>  {data.dim}  <span className="text-gray-400">||</span>   {data.date}</h1>
                    <h1 className={"text-sm m-2 text-center"} >{data.descr}</h1>
                </div>
            </div>
        </div>
    )
}

export default Content
